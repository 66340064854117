<template>
<v-select @search="onSearch" :options="options" :reduce="val => val.id" :placeholder="placeholder" label="name" style="width: 100%;" v-model="val" :multiple="multiple" @close="onClose" @open="onOpen">
    <template slot="no-options">
        {{ $t('lazyDropDown.caption') }}
    </template>
    <template #list-footer>
        <li v-show="result != null && result.after != null" ref="load" class="loader">
            Loading more options...
        </li>
    </template>
</v-select>
</template>

<script>
import _ from 'lodash';
import {
    apiCall
} from '../utils/ApiMiddleware';
export default {
    name: 'LazyDropDown',
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        value: {
            type: [String, Number, Array],
            required: false,
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        }
    },
    data: () => ({
        limit: 20,
        options: [],
        loading: false,
        val: null,
        observer: null,
        result: null,
        after: null,
        searchTerm: null,
        oldOptions: [] // used to keep the old options when searching, in this way when the user clicks outside without doing another selection, the old selection still visible
    }),
    mounted() {
        this.handleFirstValue();
        this.observer = new IntersectionObserver(this.infiniteScroll)
    },

    beforeDestroy() {},
    methods: {
        async handleFirstValue() {

            if ((this.value != null && this.value != '' && !this.multiple) || (this.value != null && this.multiple)) {
                this.val = this.value;
                const response = await apiCall('GET', this.endpoint, {
                    limit: this.limit,
                    id: this.value,
                    after: this.after
                });
                if (response.status == 200) {
                    this.result = response.data;
                    this.oldOptions = response.data.data;
                    this.options = response.data.data;
                } else if(response.status != 0 && response.status != 402){ // 402 is payment required, in this case we don't want to show the error
                    this.$vs.notification({
                        title: 'Qualcosa è andato storto!',
                        text: 'Impossibile caricare le opzioni, riprova più tardi.',
                        color: 'danger',
                        position: 'top-right'
                    })
                }
            } else if (this.multiple) {
                const response = await apiCall('GET', this.endpoint, {
                    limit: this.limit,
                    after: this.after
                });
                if (response.status == 200) {
                    this.result = response.data;
                    this.options = response.data.data;
                }
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search: _.debounce(async (loading, search, vm) => {

            const response = await apiCall('GET', vm.endpoint, {
                limit: vm.limit,
                search: search
            });
            
            // fix mac os weird apostrophe
            search = search.replaceAll('’', '\'');

            vm.searchTerm = search;
            loading(false);
            if (response.status == 200) {
                vm.options = response.data.data;
                if(vm.oldOptions.length > 0 && vm.options.find(e => e.id == vm.oldOptions[0].id) == null){ // has and old options selected and the new options don't contain it
                    vm.options = vm.oldOptions.concat(vm.options);
                }
                vm.result = response.data;
                
                await vm.$nextTick()
                vm.observer.observe(vm.$refs.load)
            } else if(response.status != 0 && response.status != 402){ // 402 is payment required, in this case we don't want to show the error
                this.$vs.notification({
                    title: 'Qualcosa è andato storto!',
                    text: 'Impossibile caricare le opzioni, riprova più tardi.',
                    color: 'danger',
                    position: 'top-right'
                })
            }
        }, 350),
        onClose() {
            this.observer.disconnect()
        },

        onOpen(){
            /*this.options = [];
            this.result = null;
            this.after = null;*/
        },

        async infiniteScroll([{
            isIntersecting,
            target
        }]) {
            console.log('test2')

            if (isIntersecting) {
                const ul = target.offsetParent
                const scrollTop = target.offsetParent.scrollTop
                this.after = this.result.after;
                await this.$nextTick()
                ul.scrollTop = scrollTop
            }
        },
    },

    watch: {
        val(val) {
            this.$emit('input', val)
        },

        value(n) {
            if (n != null) {
                this.handleFirstValue();
            } else {
                this.val = null;
            }
        },

        async after(n){
            if(n == null){
                return;
            }
            var vm = this;
            const response = await apiCall('GET', vm.endpoint, {
                limit: vm.limit,
                search: vm.searchTerm,
                after: vm.after
            });
            if (response.status == 200) {
                if(response.data.data != null && response.data.data.length > 0){
                    vm.options = vm.options.concat(response.data.data);
                }
                vm.result = response.data;
            }
        }
    }
}
</script>

<style scoped>
.loader {
    text-align: center;
    color: #bbbbbb;
}
</style>
